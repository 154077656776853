<template>
  <div>
    <AppointmentFilter
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage" />
    <b-card>
      <TitleTable titleTable="Appointment" iconTable="UsersIcon" />
      <div class="m-1">
        <AppointmentEdit
          v-if="isEditAppointmentSidebarActive"
          :is-edit-Appointment-sidebar-active.sync="isEditAppointmentSidebarActive"
          :itemEdit="itemEdit"
          @editAppointmentSuccess="editAppointmentSuccess" />
        <AppointmentPay
          v-if="conditionPayAppointment"
          :itemAppointment="itemToPay"
          @closeModalPay="closeModalPay"
          @paymentChecked="paymentChecked"
          @NotificationCheck="NotificationCheck" />
        <AppointmentCancel
          v-if="conditionCancelAppointment"
          :itemAppointment="itemToPay"
          @closeModalPay="closeModalPay"
          @paymentChecked="paymentChecked"
          @NotificationCheck="NotificationCheck" />
        <!-- <AppointmentAdd
          :is-add-new-appointment-sidebar-active.sync="
            isAddNewAppointmentSidebarActive
          "
          @createAppointment="createAppointment"
        /> -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <treeselect
              v-model="perPage"
              :options="perPageOptions"
              :clearable="true"
              class="w-25">
            </treeselect>
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <!-- <b-button variant="primary" :disabled="loading">
                <span v-if="!loading" class="text-nowrap"
                  >{{ $t("Add") }} User</span
                >
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        :items="items"
        :fields="fields"
        striped
        responsive
        bordered
        hover
        class="position-relative text-center"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc">
        <template #cell(show)="row" colspan="1">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails">
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col md="4" class="mb-1 d-flex">
                <strong>Patient : </strong>
                <b-link
                  :to="{
                    name: 'insurance-patients-view',
                    params: { id: row.item.patients.id },
                  }">
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ row.item.patients.name }}
                    {{ row.item.patients.lastname }}
                  </span>
                  <small class="text-muted">{{ row.item.patients.email }}</small>
                </b-link>
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Visit Date : </strong>{{ row.item.visit_date }}
                {{ row.item.lastname }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>{{ $t('Visit Reason') }} : </strong
                ><span>{{
                  row.item.type_consultations
                    ? row.item.type_consultations.name
                    : 'Not Found'
                }}</span>
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Type Visit : </strong>
                <span v-if="row.item.type_visits">
                  <span
                    :style="`text-decoration: underline ${row.item.type_visits.color}`"
                    >{{ row.item.type_visits.name }}</span
                  >
                </span>
                <span v-else>Not Found</span>
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Campaign : </strong>
                <span v-if="row.item.campaign_cupon">
                  <span
                    :style="`text-decoration: underline ${row.item.type_visits.color}`"
                    >{{ row.item.campaign_cupon.campaign.name }}</span
                  >
                </span>
                <span v-else>Not Found</span>
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Discount : </strong>
                <span v-if="row.item.campaign_cupon">
                  <span
                    :style="`text-decoration: underline ${row.item.type_visits.color}`"
                    >{{ row.item.campaign_cupon.campaign.value }}
                    {{
                      row.item.campaign_cupon.campaign.type == 'percentage' ? '%' : ''
                    }}</span
                  >
                </span>
                <span v-else>Not Found</span>
              </b-col>
            </b-row>

            <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails">
              Hide Details
            </b-button>
          </b-card>
        </template>
        <template #cell(patients)="data">
          <div class="text-nowrap">
            <span>{{ data.item.patients.name }} {{ data.item.patients.lastname }}</span>
          </div>
        </template>

        <template #cell(cash-cc)="data">
          <div class="text-nowrap">
            <span
              >{{ data.item.cash != null ? data.item.cash : 0 }} -
              {{ data.item.cc != null ? data.item.cc : 0 }}</span
            >
          </div>
        </template>

        <template #cell(type_visits)="data">
          <div class="text-nowrap">
            <b-badge
              :style="{ 'background-color': data.item.type_visits.color }"
              v-b-tooltip.hover.top="data.item.type_visits.name">
              <span class="align-text-top text-capitalize">{{
                data.item.type_visits.prefix
              }}</span>
              <div v-if="data.item.is_membership">
                <span
                  v-if="data.item.type_membership && data.item.membership_id"
                  class="align-text-top text-capitalize">
                  {{ data.item.type_membership }}
                </span>
              </div>
            </b-badge>
          </div>
        </template>

        <template #cell(facilities)="data">
          <b-link
            :to="{
              name: 'admin-facilities-view',
              params: { id: data.item.facilities.id },
            }">
            <span>{{ data.item.facilities.name }}</span>
          </b-link>
        </template>

        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-badge
              :style="{
                'background-color': data.item.office_visit_insurance.visit_statuses.color,
              }"
              v-b-tooltip.hover.top="data.item.office_visit_insurance.visit_statuses.name"
              class="badge-glow cursor-pointer">
              <span class="align-text-top text-capitalize">{{
                data.item.office_visit_insurance.visit_statuses.prefix
              }}</span>
            </b-badge>
          </div>
        </template>

        <template #cell(frontdesks)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.frontdesks.avatar"
                class="align-self-end" />
            </template>
            <b-link
              :to="{
                name: 'admin-users-view',
                params: { id: data.item.frontdesks.id },
              }"
              style="color: black">
              <span class="d-block text-nowrap d-flex flex-column">
                <span>
                  {{ data.item.frontdesks.name }}
                </span>
                <span>
                  {{ data.item.frontdesks.lastname }}
                </span>
              </span>
              <small>{{ data.item.frontdesks.email }}</small>
            </b-link>
          </b-media>
        </template>

        <template #cell(oma)="data">
          <b-media v-if="data.item.office_visit_insurance.omas" vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.office_visit_insurance.omas.avatar" />
            </template>
            <b-link
              :to="{
                name: 'admin-users-view',
                params: { id: data.item.office_visit_insurance.omas.id },
              }"
              style="color: black">
              <span class="font-weight-bold d-block text-nowrap d-flex flex-column">
                <span>
                  {{ data.item.office_visit_insurance.omas.name }}
                </span>
                <span>
                  {{ data.item.office_visit_insurance.omas.lastname }}
                </span>
              </span>
              <small>{{ data.item.office_visit_insurance.omas.email }}</small>
            </b-link>
          </b-media>
        </template>

        <template #cell(pay_status)="data">
          <div class="text-nowrap">
            <b-badge
              :variant="resolveUserPayStatusVariant(data.item.pay_status.name)"
              class="badge-glow cursor-pointer">
              <span class="align-text-top text-capitalize">{{
                data.item.pay_status.name
              }}</span>
            </b-badge>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <span
              v-if="data.item.office_visit_insurance.visit_statuses_id === 1"
              :id="`invoice-row-${data.item.id}-send-icon`"
              @click="cancelAppointment(data.item)">
              <i
                class="fas fa-ban text-danger cursor-pointer"
                v-b-tooltip.hover.top="'Cancel'"></i>
            </span>
            <span
              v-if="
                data.item.office_visit_insurance.visit_statuses_id === 4 &&
                verificarFecha(data.item.visit_date)
              "
              :id="`invoice-row-${data.item.id}-send-icon`"
              @click="payAppointment(data.item)">
              <i
                class="far fa-money-bill-alt text-success mx-1 cursor-pointer"
                v-b-tooltip.hover.top="'Pay'"></i>
            </span>
            <router-link
              v-if="data.item.office_visit_insurance.visit_statuses_id === 5"
              :to="{
                name: 'insurance-recipe-patient',
                params: { id: data.item.office_visit_insurance.id },
              }">
              <i
                class="fas fa-print text-success mx-1 cursor-pointer"
                v-b-tooltip.hover.top="'Receipt'"></i>
            </router-link>
          </div>
        </template>

        <template #cell(membership_id)="row">
          <span>
            <i
              :class="
                row.item.type_visits.id == 8
                  ? 'fa fa-star text-warning'
                  : 'fa fa-star text-muted'
              "></i>
          </span>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged" />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'

import GoBack from '@/components/ButtonBack'
import TitleTable from '@/components/TitleTable.vue'
import ToastNotification from '@/components/ToastNotification'
import Swal from 'sweetalert2'
import PaginationTable from '@/components/PaginationTable'

import AppointmentEdit from './actions/AppointmentAdd.vue'
import AppointmentFilter from './actions/AppointmentFilter.vue'
import AppointmentPay from './actions/AppointmentPay'
import AppointmentCancel from './actions/AppointmentCancel'

import axiosA from '@/core/services/apiInsurance/appointment'

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BLink,
    BMedia,
    BAvatar,

    GoBack,
    ToastNotification,
    PaginationTable,
    TitleTable,

    AppointmentEdit,
    AppointmentFilter,
    AppointmentPay,
    AppointmentCancel,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      fields: [
        { key: 'show', class: 'th-class-adjusted-appointment' },
        { key: 'id', sortable: true, class: 'th-class-adjusted-appointment' },
        {
          key: 'patients',
          label: 'Patient',
          sortable: true,
          class: 'th-class-adjusted-appointment',
        },
        {
          key: 'visit_date',
          label: 'Visit Date',
          sortable: true,
          class: 'th-class-adjusted-appointment',
        },
        {
          key: 'type_visits',
          label: 'Type Visit',
          sortable: true,
          class: 'th-class-adjusted-appointment',
        },
        {
          key: 'facilities',
          label: 'Facility',
          sortable: true,
          class: 'th-class-adjusted-appointment',
        },

        {
          key: 'frontdesks',
          label: 'Frontdesk',
          sortable: true,
          class: 'th-class-adjusted-appointment',
        },
        {
          key: 'oma',
          label: 'Oma',
          sortable: true,
          class: 'th-class-adjusted-appointment',
        },
        {
          key: 'status',
          sortable: true,
          class: 'th-class-adjusted-appointment',
        },
        {
          key: 'ajustment',
          label: 'Adjusment',
          sortable: true,
          class: 'th-class-adjusted-appointment',
        },
        {
          key: 'cash-cc',
          label: 'Cash - Cc',
          sortable: true,
          class: 'th-class-adjusted-appointment',
        },
        {
          key: 'total',
          label: 'Total',
          sortable: true,
          class: 'th-class-adjusted-appointment',
        },

        {
          key: 'pay_status',
          label: 'Pay Status',
          sortable: true,
          class: 'th-class-adjusted-appointment',
        },

        { key: 'actions', class: 'th-class-adjusted-appointment' },
        {
          key: 'membership_id',
          label: 'Membership',
          class: 'th-class-adjusted-appointment',
        },
      ],
      items: [],
      perPageOptions: [10, 25, 50, 100].map(i => ({
        id: i,
        label: `Show ${i} Entries`,
      })),
      statusOptions: [
        { text: 'Active', value: 'true' },
        { text: 'Inactive', value: 'false' },
      ],
      perPage: 10,
      loading: false,
      isSortDirDesc: true,
      sortBy: 'id',
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      currentPage: {
        page: 1,
      },
      isAddNewAppointmentSidebarActive: false,
      isEditAppointmentSidebarActive: false,
      conditionPayAppointment: false,
      conditionCancelAppointment: false,
      itemToPay: null,
      filtro: false,
      appointmentFilter: null,
    }
  },
  created() {
    this.channel = this.$pusher.subscribe(
      `officeVisit.${JSON.parse(atob(localStorage.getItem('Facility')))}`
    )
    this.channel.bind('officeVisitEvent', async ({ event }) => {
      if (!this.filtro) {
        if (event.type === 'office_visiit' || event.type === 'appointment') {
          this.getAppointments()
        }
      } else {
        if (event.type === 'office_visiit' || event.type === 'appointment') {
          this.searchFilter(this.userFilter)
        }
      }
    })
    const rolMain = JSON.parse(localStorage.getItem('userData')).roles[0]
    if (rolMain.id !== 1) this.fields.splice(6, 1)
    /* const channel = this.$pusher.subscribe(
          `officeVisit.${JSON.parse(atob(localStorage.getItem("Facility"))).id}`
        );
        channel.bind("officeVisitEvent", ({ event }) => {
          if (!this.filtro) {
            if (event.type === "office_visiit" || event.type === "appointment") {
              this.getAppointments();
            }
          }
        }); */
    // this.getAppointments();
  },
  /* destroyed() {
      this.$pusher.unsubscribe(
        `officeVisit.${JSON.parse(atob(localStorage.getItem("Facility"))).id}`
      );
    }, */
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getAppointments()
      } else {
        this.searchFilter(this.appointmentFilter)
      }
    },
    '$variableGlobal.facility': function (value) {
      this.getAppointments()
      // pusher
      /* this.$pusher.unsubscribe(
              `officeVisit.${JSON.parse(atob(localStorage.getItem("Facility"))).id}`
            );
            const channel = this.$pusher.subscribe(
              `officeVisit.${JSON.parse(atob(localStorage.getItem("Facility"))).id}`
            );
            channel.bind("officeVisitEvent", ({ event }) => {
              if (!this.filtro) {
                if (event.type === "office_visiit" || event.type === "appointment") {
                  this.getAppointments();
                }
              }
            }); */
    },
  },
  methods: {
    verificarFecha(fecha) {
      const dateVisit = new Date(fecha).setHours(0, 0, 0, 0)
      const dateNow = new Date(fecha).setHours(0, 0, 0, 0)

      let a = new Date(
        fecha.split('-')[2],
        Number(fecha.split('-')[0]) - 1,
        fecha.split('-')[1]
      ).toLocaleString()
      let user = JSON.parse(localStorage.getItem('userData'))
      // Si la fecha actual es mayor a la de la visita no mostrar el boton de checkout
      switch (dateNow > dateVisit) {
        case true:
          if (user.role == 'superadmin') {
            return true
          }

          return false
        case false:
          return true
      }
    },
    getAppointments() {
      axiosA.appointmentList(this.perPage).then(({ total, current_page, ...res }) => {
        this.items = res.data
        this.totalUsers = total
        this.dataMeta.of = total
        this.dataMeta.from = res.from
        this.dataMeta.to = res.to
        this.currentPage.page = current_page
      })
    },
    resolveUserStatusVariant(status) {
      if (status) return 'success'
      return 'danger'
    },
    resolveUserPayStatusVariant(status) {
      if (status === 'Paid') return 'success'
      if (status === 'Unpaid') return 'warning'
    },
    createAppointment({ type, message }) {
      this.NotificationCheck({ type, message })

      if (type === 'danger') {
        this.isAddNewAppointmentSidebarActive = false
      }
      this.pageChanged(this.currentPage.page)
    },
    deleteAppointment(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axiosA.appointmentDelete(item.id).then(response => {
            this.$refs.toast.success('Appointment has been deleted successfully')
            this.pageChanged(this.currentPage.page)
          })
        }
      })
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true
        this.appointmentFilter = value
        axiosA
          .appointmentFilter(this.perPage, value)
          .then(({ registro: { data, total, current_page, ...res } }) => {
            this.loading = false
            this.items = data
            this.totalUsers = total
            this.dataMeta.of = total
            this.dataMeta.from = res.from
            this.dataMeta.to = res.to
            this.currentPage.page = current_page
          })
          .catch(() => {
            this.loading = false
          })
      } else {
        this.getAppointments()
      }
    },
    filterPage(value) {
      this.filtro = value
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosA
          .appointmentPagination(this.perPage, page)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data
            this.totalUsers = total
            this.dataMeta.of = total
            this.dataMeta.from = res.from
            this.dataMeta.to = res.to
            this.currentPage.page = current_page
          })
      } else {
        axiosA
          .appointmentFilterPagination(this.perPage, page, this.appointmentFilter)
          .then(({ registro: { data, total, current_page, ...res } }) => {
            this.items = data
            this.totalUsers = total
            this.dataMeta.of = total
            this.dataMeta.from = res.from
            this.dataMeta.to = res.to
            this.currentPage.page = current_page
          })
      }
    },
    payAppointment(item) {
      this.itemToPay = { ...item }
      this.conditionPayAppointment = true
    },
    cancelAppointment(item) {
      this.itemToPay = item
      this.conditionCancelAppointment = true
    },
    closeModalPay() {
      this.conditionPayAppointment = false
      this.conditionCancelAppointment = false
      this.itemToPay = null
    },
    paymentChecked(value) {
      this.pageChanged(this.currentPage.page)
    },
    NotificationCheck({ type, message }) {
      this.$refs.toast[type](message)
    },
  },
}
</script>
<style lang="scss">
.th-class-adjusted-appointment {
  padding: 0.9rem 0 !important;
}
</style>
